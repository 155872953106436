<template>
  <nav class="top-sub-menu">
    <div class="subMenucontainer" v-if="$route.path !== '/recipes'">
      <transition name="slide-fade">
        <div
          class="mapperContainer"
          v-if="selectedType !== null &&
            (selectedType === 'hotels' ||
            selectedType === 'restaurants' ||
            selectedType === 'experiences'
          )">
          <div class='input-where-mapper'>
            <i class="fal fa-map-marker-alt"></i>
            <input
              class="input-where-text"
              id="google-map"
              ref="googlePlacesInput"
              type="text"
              :placeholder="'Where ?'"
              v-model="inputValue"
            />
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div
          class="dropdownContainer"
          :style="{
            'overflow': windowWidth > 975 ? 'inherit' : 'auto'
          }"
          v-if="selectedType !== null &&
            (selectedType !== 'hotels' &&
            selectedType !== 'restaurants' &&
            selectedType !== 'experiences' /*&&
            selectedType !== '' */
            )"
        >
          <!-- Iterate through the categoryButtonDropdowns object to determine which buttons to load -->
          <div
            class="dropdown"
            v-for="(buttonDropdown, buttonIndex) in categoryButtonDropdowns"
            :key="buttonDropdown.buttonText"
            :style="{
              'z-index': 50 - buttonIndex,
              'display': windowWidth > 975 ? 'inline-block' : 'contents'
            }"
          >
            <button
              class="btn-browse-dropdown"
              :style="{
                'background-color': displayDropdown && selectedButtonIndex === buttonIndex ? '#eeeeee' : '#ffffff'
              }"
              @click.prevent.stop="buttonDropdown.dropdownContent.length > 1
                ? handleDropdown(buttonIndex)
                : handlePressedCategory(buttonDropdown.dropdownContent[0].slug)"
              @touchend="buttonDropdown.dropdownContent.length > 1
                ? handleMobileClick(buttonDropdown.buttonText)
                : handlePressedCategory(buttonDropdown.dropdownContent[0].slug)"
              @touchmove="preventOpenMenu = true"
            >
              <span style="display: flex; align-items: center">
                {{ buttonDropdown.buttonText }}
                <img
                  v-if="buttonDropdown.dropdownContent.length > 1"
                  src="@/assets/angle-down.svg"
                  alt="sub-category arrow"
                  style="height: 17px; width: 17px; padding-left: 5px" />
              </span>
            </button>
            <div v-if="displayDropdown && selectedButtonIndex === buttonIndex" class="dropdown-content" style="display: flex">
              <div
                v-if="selectedButtonIndex !== -1"
                class="dropdown-col"
              >
                <button
                  v-for='(category, index) in categoryButtonDropdowns[selectedButtonIndex].dropdownContent'
                  :key='`shopping-menu-${category.slug}`'
                  :class="{'dropdown-button-selected': selectedFirstCategory === index}"
                  @click='() => handlePressedCategory(category.slug)'
                  @mouseenter='selectedFirstCategory = index; selectedSecondCategory = -1'
                >
                  <span style="display: flex; justify-content: space-between">
                    <span style="padding-right: 10px;">
                      {{ category.name }}
                    </span>
                    <img
                      v-if="category.subCategories"
                      src="@/assets/angle-down.svg"
                      alt="sub-category arrow"
                      style="height: 14px; width: 14px; transform: rotate(-90deg);" />
                  </span>
                </button>
              </div>
              <div
                v-if="selectedButtonIndex !== -1 &&
                      selectedFirstCategory !== -1 &&
                      categoryButtonDropdowns[selectedButtonIndex].dropdownContent[selectedFirstCategory].subCategories"
                class="dropdown-col"
              >
                <button
                  v-for='(subCategory, index) in categoryButtonDropdowns[selectedButtonIndex].dropdownContent[selectedFirstCategory].subCategories'
                  :key='`shopping-menu-subCat-${subCategory.slug}`'
                  :class="{'dropdown-button-selected': selectedSecondCategory === index }"
                  @click='() => handlePressedCategory(subCategory.slug)'
                  @mouseenter='selectedSecondCategory = index'
                >
                  <span style="display: flex; justify-content: space-between">
                    <span style="padding-right: 10px;">
                      {{ subCategory.name }}
                    </span>
                    <img
                      v-if="subCategory.subCategories"
                      src="@/assets/angle-down.svg"
                      alt="sub-category arrow"
                      style="height: 14px; width: 14px; transform: rotate(-90deg);" />
                  </span>
                </button>
              </div>
              <div
                v-if="selectedButtonIndex !== -1 &&
                      selectedFirstCategory !== -1 &&
                      selectedSecondCategory !== -1 &&
                      categoryButtonDropdowns[selectedButtonIndex].dropdownContent[selectedFirstCategory]
                        .subCategories[selectedSecondCategory].subCategories"
                class="dropdown-col"
              >
                <button
                  v-for='subSubCategory in categoryButtonDropdowns[selectedButtonIndex].dropdownContent[selectedFirstCategory].subCategories[selectedSecondCategory].subCategories'
                  :key='`shopping-menu-subSubCat-${subSubCategory.slug}`'
                  @click='() => handlePressedCategory(subSubCategory.slug)'
                >
                  {{ subSubCategory.name }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="displayDropdown"
            class="background-close-dropdown"
            @click='() => { handleDropdown(-1, false) }'
          ></div>
        </div>
      </transition>
      <!-- <transition name="slide-fade">
        <div
          v-if="$store.state.filteringQuery.length"
          class="displayFilteringQuery"
        >
          {{ $store.state.filteringQuery }}
          <button
            class="queryButton"
            @click.stop="handleClearQuery"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </transition> -->
    </div>
  </nav>
</template>

<script>
// import categoriesCollections from '@/categoriesCollections'
// eslint-disable-next-line no-unused-vars
import { specialityShops } from '@/menuCategories.js'

export default {
  name: 'TopSubMenu',
  props: {
    //
  },
  data: () => ({
    preventOpenMenu: false,
    isMobileClick: false,
    selectedButtonIndex: -1,
    categoryButtonDropdowns_old: [
      {
        buttonText: 'Groceries',
        dropdownContent: [
          {
            name: 'All products',
            slug: 'online-shopping'
          }
          // ...categoriesCollections
        ]
      },
      {
        buttonText: 'Speciality Shops',
        dropdownContent: specialityShops
      }
    ],
    // categoriesCollections,
    // categories_old: [{ name: 'Groceries', slug: 'online-shopping' }, ...categoriesCollections],
    specialityShops,
    selectedFirstCategory: -1,
    selectedSecondCategory: -1,
    selectedType: null,
    inputValue: '',
    autocomplete: null,
    displayDropdown: false,
    selectedCategory: 'Groceries',
    windowWidth: window.innerWidth
  }),
  computed: {
    categoryButtonDropdowns () {
      const serverFetchedCategories = this.$store.state.serverFetchedCategories
      return [
        {
          buttonText: 'Groceries',
          dropdownContent: [
            {
              name: 'All products',
              slug: 'online-shopping'
            },
            ...serverFetchedCategories
          ]
        },
        {
          buttonText: 'Speciality Shops',
          dropdownContent: specialityShops
        }
      ]
    },
    categories () {
      const serverFetchedCategories = this.$store.state.serverFetchedCategories
      return [{ name: 'Groceries', slug: 'online-shopping' }, ...serverFetchedCategories]
    }
  },
  watch: {
    $route (to, from) {
      this.selectedType = to.path.substr(1).replace('-', ' ')

      // Variable used to check if the category has been found
      // If !== undefined => Encountered a match
      let matchedCategory
      const slug = to.params.slug
      this.categories.forEach(cat => {
        if (matchedCategory !== undefined) return // No need to search anymore
        if (cat.slug === slug) { // Found a match, update matchedCategory value
          matchedCategory = cat.name
          return
        }
        if (cat.subCategories) {
          cat.subCategories.forEach(cat2 => {
            if (matchedCategory !== undefined) return // No need to search anymore
            if (cat2.slug === slug) { // Found a match, update matchedCategory value
              matchedCategory = cat2.name
              return
            }
            if (cat2.subCategories) {
              cat2.subCategories.forEach(cat3 => {
                if (matchedCategory !== undefined) return // No need to search naymore
                if (cat3.slug === slug) { // Found a match, update matchedCategory value
                  matchedCategory = cat3.name
                }
              })
            }
          })
        }
      })
      this.selectedCategory = matchedCategory === 'Groceries' ? 'Groceries' : matchedCategory || 'Groceries'
    },
    categoryButtonDropdowns () {
      this.findcategoryButtonDropdownTrigger()
    }
  },
  mounted () {
    this.selectedType = this.$route.path.substr(1).replace('-', ' ')

    // this.getCategoryObject('vk_cuisines')
    // this.getCategoryObject('vk_healthnutritionwellbeing')
    // this.getCategoryObject('vk_healthbeauty')
    // this.getCategoryObject('vk_proteinsources')
    // this.getCategoryObject('vk_deals')
    // this.getCategoryObject('vk_eastercollection')
    // this.getCategoryObject('vk_christmas')

    var input = document.getElementById('google-map')
    /* eslint-disable-next-line */
    this.autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['geocode']
    })

    this.autocomplete.addListener('place_changed', () => {
      // User selects a place
      const place = this.autocomplete.getPlace()
      this.inputValue = place.formatted_address
      const lng = place.geometry.location.lng()
      const lat = place.geometry.location.lat()
      const geo = lat + ',' + lng
      this.$store.commit('setFilteringGeo', geo)
      this.handleCatagory(this.selectedType)
    })

    // Variable used to check if the category has been found
    // If !== undefined => Encountered a match
    let matchedCategory
    const slug = this.$route.params.slug
    // console.log('slug')
    // console.log(slug)
    this.categories.forEach(cat => {
      if (matchedCategory !== undefined) return // No need to search anymore
      if (cat.slug === slug) { // Found a match, update matchedCategory value
        matchedCategory = cat.name
        return
      }
      if (cat.subCategories) {
        cat.subCategories.forEach(cat2 => {
          if (matchedCategory !== undefined) return // No need to search anymore
          if (cat2.slug === slug) { // Found a match, update matchedCategory value
            matchedCategory = cat2.name
            return
          }
          if (cat2.subCategories) {
            cat2.subCategories.forEach(cat3 => {
              if (matchedCategory !== undefined) return // No need to search naymore
              if (cat3.slug === slug) { // Found a match, update matchedCategory value
                matchedCategory = cat3.name
              }
            })
          }
        })
      }
    })
    this.selectedCategory = matchedCategory === 'Groceries' ? 'Groceries' : matchedCategory || 'Groceries'

    // Add Event Listener in case the window resizes
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  beforeMount () {
    if (this.categoryButtonDropdowns[0].dropdownContent.length > 1) {
      this.findcategoryButtonDropdownTrigger()
    }
  },
  methods: {
    findcategoryButtonDropdownTrigger () {
      this.getCategoryObject('vk_cuisines')
      this.getCategoryObject('vk_healthnutritionwellbeing')
      this.getCategoryObject('vk_healthbeauty')
      this.getCategoryObject('vk_proteinsources')
      this.getCategoryObject('vk_deals')
      this.getCategoryObject('vk_eastercollection')
    },
    getCategoryObject (collection, collectionsToSearch = this.$store.state.serverFetchedCategories) {
      // * Check if collections have already been added
      if (this.categoryButtonDropdowns.some(col => col.collection === collection)) { return }
      // ? Iterate through categoriesCollections object/array, and find the objects we
      // ? want to shop in the topSubMenu section of the website
      // Todo: Update method used from "forEach" to something better performance-wise
      collectionsToSearch.forEach(el => {
        // ? This will only search on the first "depth" ,
        // ? to get more in depth of the collections, add a recursive call in the future
        if (collection === el.collection) {
          const returnObject = {
            buttonText: el.name,
            dropdownContent: [
              {
                name: el.name,
                slug: el.slug
              }
            ]
          }
          if (el.subCategories) {
            returnObject.dropdownContent = [...returnObject.dropdownContent, ...el.subCategories]
          }
          this.categoryButtonDropdowns.push(returnObject)
        } else if (el.subCategories !== undefined) {
          return this.getCategoryObject(collection, el.subCategories)
        }
      })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    handleCatagory (catagory) {
      const query = {}
      this.selectedType = catagory

      if (catagory === 'online shopping') {
        this.$store.commit('setFilteringGeo', undefined)
        this.inputValue = ''
      }

      this.$router.push({
        name: catagory,
        query
      })
    },
    handleMobileClick (buttonText) {
      // console.log('mobile')
      this.isMobileClick = true
      if (this.preventOpenMenu) {
        this.preventOpenMenu = false
        return
      }
      this.$store.commit('setDefaultSideMenuCategory', buttonText === 'Groceries' ? 'Shop' : buttonText)
      this.$store.commit('setSideMenuVisibility', !this.$store.state.sideMenuHidden)
    },
    handleDropdown (index, newVal = null) {
      // console.log('trying')
      this.selectedButtonIndex = index
      if (this.windowWidth < 620 || this.isMobileClick) {
        // this.$store.commit('setDefaultSideMenuCategory', 'Shop')
        // this.$store.commit('setSideMenuVisibility', !this.$store.state.sideMenuHidden)
        this.isMobileClick = false
        return
      }
      if (newVal === null) {
        this.displayDropdown = !this.displayDropdown
        if (!this.displayDropdown) {
          // Clear selected "dropdown buttons" to avoid errors
          this.selectedButtonIndex = -1
          this.selectedFirstCategory = -1
          this.selectedSecondCategory = -1
        }
      } else {
        this.displayDropdown = newVal
        if (newVal === false) {
          this.selectedButtonIndex = -1
          this.selectedFirstCategory = -1
          this.selectedSecondCategory = -1
        }
      }
    },
    handlePressedCategory (category) {
      const query = {}
      this.selectedType = 'online shopping'

      if (this.$route.path !== `/c/${category}`) {
        this.$router.push({
          path: category.includes('/o/') ? category : `/c/${category}`,
          query
        })
      }

      this.handleDropdown(-1, false)
    },
    handleClearQuery () {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete('query')
      this.$router.replace('?' + queryParams.toString())
      this.$store.commit('setFilteringQuery', '')
      this.$store.commit('updateSearchValue', '')
    }
  }
}
</script>

<style lang='stylus' scoped>
.top-sub-menu {
  padding: 0px 10px 15px;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  box-sizing: border-box;
  background: white;
  z-index: 12;
  display: block;
  text-align: initial;

  .subMenucontainer {
    display: flex;
    align-items: center;

    .displayFilteringQuery {
      padding: 5px 12px;
      background: #DED7E8;
      border-radius: 20px;

      .queryButton {
        border: 0px;
        background: inherit;
        cursor: pointer;

        .clearSearchIcon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
  }

  .btn-custom-white {
    background-color: white;
    border-radius: 5px;
    border: solid 1px #b3b3b3;
    padding: 10px 12px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    cursor: pointer;
    color: #000;
  }
  .active {
    background-color: #d9d9d9;
  }

  .btn-browse-dropdown {
    color: black;
    background-color: white;
    border-radius: 5px;
    // border: solid 1px #b3b3b3;
    border: none
    padding: 10px 5px;
    margin-right: 10px;
    font-size: 15px;
    cursor: pointer;

    i {
      padding-left: 10px;
    }
  }
  .active {
    background-color: #d9d9d9;
  }

  .mapperContainer {
    margin-right: 20px
  }

  .dropdownContainer {
    white-space: nowrap;
    // overflow: auto;
  }
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }

  .dropdown {
    position: relative;
    // display: inline-block;
    display: contents;
    z-index: 15
  }

  .dropdown-content {
    white-space: normal;
    position: absolute;
    width: max-content;
    // min-width: 225px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
    border-radius: 5px;
    z-index: 50;
    .dropdown-col {
      // width: fit-content;
      width: 200px;
    }
  }

  .dropdown-content button {
    color: black;
    padding: 10px 14px;
    text-decoration: none;
    display: block
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  .dropdown-content button:hover {
    background-color: #eeeeee;
  }

  .dropdown-button-selected {
    background-color: #f0f0f0 !important;
  }

  .background-close-dropdown {
    width: 100%;
    height: 100vh;
    z-index: 14;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed
  }

  .input-where-mapper {
    border: solid 1px #b3b3b3;
    border-radius: 5px;
    padding: 10px 12px;
    width: fit-content;
    margin-top: 5px;
    .input-where-text {
      padding: 0 10px;
      border: none;
      outline: none;
      width: 100px;
      font-size: 15px;
      &::placeholder {
        color: inherit;
        opacity: 1;
      }
    }
    i {
      font-size: 18px;
    }
  }
}
</style>>
