<template>
  <div class="ViewContainer">
    <router-view
      @addItemToCart="$emit('addItemToCart', $event)"
      @openMagicSidebar="$emit('openMagicSidebar')"
      @openSnackbar="$emit('openSnackbar', $event)"
      @makePurchase="$emit('makePurchase', $event)"
      @openSignInPage="$emit('openSignInPage', $event)"
      @removeOrder="$emit('removeOrder', $event)"
    ></router-view>
  </div>
</template>

<script>
export default {
  props: {
    sideMenuHidden: Boolean
  },
  name: 'ViewContainer',
  watch: {
    '$route': {
      handler () {
        document.getElementsByClassName('ViewContainer')[0].scrollTop = 0
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.ViewContainer
  height 100%
  // overflow-y scroll /* has to be scroll, not auto */
  -webkit-overflow-scrolling touch
  // overflow-x hidden
</style>
