var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('modal-box',{ref:"confirmPaymentModal",attrs:{"title":"Payment Confirmation","iframeUrl":_vm.$store.state.iframePaymentUrl,"type":"no-footer"}}),_c('gallery',{attrs:{"images":_vm.$store.state.galleryImages,"index":_vm.$store.state.openImageIndex},on:{"close":function($event){return _vm.$store.commit('setOpenImageIndex', null)}}}),_c('top-banner',{on:{"openProfileSettings":_vm.openProfileSettings}}),_c('top-links'),(!_vm.$route.path.includes('/account'))?_c('top-menu',{attrs:{"userLoggedIn":_vm.userLoggedIn,"userData":_vm.userData,"showCategoryDropdown":true,"notificationsQuantity":_vm.$refs.magicSidebar ? _vm.$refs.magicSidebar.notifications ? _vm.$refs.magicSidebar.notifications.length : 0 : 0,"ordersQuantity":_vm.$refs.magicSidebar ? _vm.$refs.magicSidebar.itemsOnCart : 0,"sideMenuHidden":_vm.$store.state.sideMenuHidden},on:{"hamburgerClicked":_vm.hamburgerClicked,"openCart":_vm.openCart,"openMagicSidebar":_vm.openSidebar}}):_vm._e(),(_vm.$route.meta.showSubMenu)?_c('top-sub-menu'):_vm._e(),_c('MagicSideBar',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function (e) { (!e.target.className.includes('userCart') && _vm.windowWidth > 520) ? _vm.magicSideBarOpen = false : null }),expression:"(e) => { (!e.target.className.includes('userCart') && windowWidth > 520) ? magicSideBarOpen = false : null }"}],ref:"magicSidebar",staticStyle:{"z-index":"55"},attrs:{"hidden":!_vm.magicSideBarOpen,"displayLoginIssuesWarning":false,"staging":_vm.isDev,"apiUrl":_vm.apiUrl,"stripeKey":_vm.stripeKey,"enableRewardful":true,"imgixDomain":_vm.imgixDomain,"minPriceToOrder":50,"disableSubscriptionsStep":true,"disableDeliveryStep":false,"enableDeleteAccount":true,"retryPaymentInterval":5,"enableLoyaltyPoints":true,"unavailableDeliveryDates":[
      '2024/03/29',
      '2024/03/30',
      '2024/03/31',
      '2024/04/01',
      '2024/04/02' ],"useExternalCheckout":true,"deliveryMessageBackground":"#3BAA5B","deliveryMessageTextColor":"#FFFFFF"},on:{"tokenChanged":_vm.handleCSRFtokenChanged,"openSignInPage":_vm.openSignInPage,"signedIn":_vm.handleSignedIn,"signedOut":_vm.handleSignedOut,"authPurchase":_vm.handlePurchase3d,"close":function () { _vm.magicSideBarOpen = false }}}),_c('SideMenu',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.handleSideMenuVisibility(true); }),expression:"() => handleSideMenuVisibility(true)"}],staticStyle:{"z-index":"50"},attrs:{"hidden":_vm.$store.state.sideMenuHidden,"categories":_vm.menuCategories,"logo-url":require('./assets/logo.svg'),"social-urls":{
      facebook: 'https://www.facebook.com/thevegankind',
      instagram: 'https://www.instagram.com/thevegankind/',
      twitter: 'https://twitter.com/thevegankind',
      linkedin: 'https://www.linkedin.com/company/thevegankind/'
    },"about-path":'/about',"contact-path":'/contact',"privacy-path":'/privacy',"listing-path":'/add-listing',"delivery-path":'/delivery',"affiliate-path":'/friends',"external-link":'https://wholefoodearth.com',"external-link-text":"Whole Food Earth ®","terms-and-conditions-path":'/terms-and-conditions',"app-path":'/app',"listingNewPage":false,"clearQueryOnSelect":true,"preSelectedCategory":_vm.$store.state.defaultSideMenuCategory},on:{"requestCloseSideMenu":function($event){return _vm.handleSideMenuVisibility(true)},"handleClearQuery":_vm.handleClearQuery}}),_c('ais-instant-search',{attrs:{"search-client":_vm.algoliaClient,"index-name":_vm.algoliaIndex}},[_c('snackbar',{ref:"snackbar",attrs:{"base-size":"100px","hold-time":5000,"position":"bottom-center"}}),_c('div',{staticClass:"routerView",class:{'routerView--full' : _vm.$store.state.sideMenuHidden}},[_c('transition',{attrs:{"name":!_vm.$route.path.includes('/account') ? 'slide-fade' : null}},[(!_vm.notFound)?_c('router-view',{key:_vm.$route.path,on:{"makePurchase":_vm.makePurchase,"addItemToCart":_vm.addItemToCart,"openMagicSidebar":function () { _vm.magicSideBarOpen = true },"openPurchases":_vm.openPurchases,"openUserContacts":_vm.openUserContacts,"openSnackbar":_vm.openSnackbar,"openSignInPage":_vm.openSignInPage,"removeOrder":_vm.removeOrder}}):_c('p404')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }