// import categoriesCollections from './categoriesCollections'

// const CATEGORIES_ROUTE = '/c/'

export const specialityShops = [
  {
    name: 'Happy Beans',
    slug: '/o/happy-beans'
  },
  {
    name: 'La Fauxmagerie',
    slug: '/o/la-fauxmagerie'
  },
  {
    name: 'Fabulous Freedom Factory',
    slug: '/o/fabulous-free-from-factory'
  },
  {
    name: 'Fodabox',
    slug: '/o/fodabox-organisation'
  },
  {
    name: 'Harmless Market',
    slug: '/o/harmless-market'
  },
  {
    name: 'The Good Grocer',
    slug: '/o/vegan-kind'
  },
  {
    name: 'Whole Food Earth',
    slug: '/o/wholefood-earth'
  }
]

export default getCategories()

// The point of this function is to manage routes on one place only, and automatically
// Add the new routes here, and by doing that, automatically pass them to <SideMenu />
function getCategories () {
  const categories = {
    'Shop': { },
    'Speciality Shops': { },
    'book': {
      'hotels': 'hotels',
      'restaurants': 'restaurants',
      'experiences': 'experiences'
    },
    'recipes': '/recipes',
    'magazine': '/mag'
  }

  // Populate Speciality Shops
  specialityShops.forEach(shop => {
    categories['Speciality Shops'][shop.name] = shop.slug
  })

  // Iterate all the category Collections
  // categoriesCollections.forEach(category => {
  //   if (category.subCategories) {
  //     // If the category has Sub-categories, it needs to add the Category as an object
  //     if (category.collection) {
  //       // If it has a collection associated, add the "All" option to display the
  //       // said collection
  //       categories.Shop[category.name] = { 'All': CATEGORIES_ROUTE + category.slug }
  //     } else {
  //       // If it doesn't have a collection associated, no need to add the "All" to include
  //       // itself
  //       categories.Shop[category.name] = {}
  //     }
  //     // Iterate Sub-categories (2nd level)
  //     category.subCategories.forEach(subCategory => {
  //       if (subCategory.subCategories) {
  //         // If has sub-categories, Add the "All" category
  //         if (subCategory.collection) {
  //           // If it has a collection associated, add the "All" option to display the
  //           // said collection
  //           categories.Shop[category.name][subCategory.name] = { 'All': CATEGORIES_ROUTE + subCategory.slug }
  //         } else {
  //           // If it doesn't have a collection associated, no need to add the "All" to include
  //           // itself
  //           categories.Shop[category.name][subCategory.name] = {}
  //         }
  //         // Iterate Subcategories (3rd level)
  //         subCategory.subCategories.forEach(subSubCategory => {
  //           // ! There should not be more levels than this
  //           // Add the 3rd level categories to the menu
  //           categories.Shop[category.name][subCategory.name][subSubCategory.name] = CATEGORIES_ROUTE + subSubCategory.slug
  //         })
  //       } else {
  //         categories.Shop[category.name][subCategory.name] = CATEGORIES_ROUTE + subCategory.slug
  //       }
  //     })
  //   } else {
  //     // Simply add the category with it's corresponding slug to the menu
  //     categories.Shop[category.name] = CATEGORIES_ROUTE + category.slug
  //   }
  // })

  return categories
}
