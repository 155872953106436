import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'english',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('english').create({
      topMenu: {
        everything: 'Everything',
        restaurants: 'restaurants',
        restaurant: 'restaurant',
        hotels: 'hotels',
        hotel: 'hotel',
        cafes: 'cafes',
        cafe: 'cafe',
        magazine: 'magazine',
        article: 'article',
        articles: 'articles',
        type: 'type',
        keywords: 'keywords',
        where: 'where',
        when: 'when',
        location: 'Location',
        title: 'title',
        back: 'Go back'
      },
      sideMenu: {
        featured: 'featured',
        eat: 'eat',
        restaurants: 'restaurants',
        cafes: 'cafes',
        travel: 'travel',
        hotels: 'hotels',
        holidays: 'holidays',
        experiences: 'experiences',
        fashion: 'fashion',
        recipe: 'recipe',
        shops: 'shops',
        products: 'products',
        magazine: '#magazine',
        installApp: 'install app',
        addListing: 'add listing',
        contact: 'contact',
        privacy: 'privacy',
        blog: 'blog'
      },
      item: {
        day: 'day',
        hours: 'hours',
        rooms: 'rooms',
        offers: 'offers',
        photos: 'photos',
        about: 'about',
        opening_hours: 'opening hours',
        amenities: 'amenities',
        map: 'map',
        reviews: 'reviews',
        nutritional_facts: 'nutritional information',
        ingredients: 'ingredients',
        contact: 'contact'
      },
      weekDays: {
        monday: 'monday',
        tuesday: 'tuesday',
        wednesday: 'wednesday',
        thursday: 'thursday',
        friday: 'friday',
        saturday: 'saturday',
        sunday: 'sunday'
      },
      mobileAppAdvert: {
        header: 'Take the Vegan Kind app everywhere you go.',
        description: 'There are always a few vegan restaurants and hotels near you, and we help you get there.'
      },
      addListingPage: {
        header: 'Add Listing',
        description: `Would you like to see your favourite vegan product or venue on the Vegan Kind market?
          Do you own or run a vegan restaurant, cafe, shop or vegan-friendly hotel?
          You can easily add the item or venue to the listing. Upload your favourite vegan product, place or business on Vegan Kind and help others discover it!`
      },
      homepage: {
        title: '',
        description: `Hi, you're <b>@kiuki</b>.
          Discover the <a>#bestveganplaces</a> around the world.
          Convince yourself that <a>#veganisbetter</a>.
          At <a>#kiuki</a> there is <a>#nocompromise</a> on your values.
          Your conscious choices have the power to save the animals and help the planet.`
      },
      packagedfood: {
        title: 'Shopping',
        description: `package food`
      },
      restaurant: {
        title: 'Restaurants',
        description: `Experience exceptional vegan dining.
          Expect best plant-based dishes prepared of the seasonal ingredients and created by people who are passionate about vegan cooking and lifestyle. Healthy and delicious food that is better for you and for the environment. Join the food culture revolution and connect to the places that were designed to deliver the best vegan cuisine.
          <a>#veganisbetter</a>`
      },
      hotel: {
        title: 'Hotels',
        description: `Experience a vegan stay in one of our hotels around the world.
          Expect comfortable accommodation, vegan dining and vegan-friendly service.
          Get to know the hotels that combine quality with cruelty-free philosophy,
          healthy food with local cultures, and sustainability efforts to save the planet.
          <a>#veganisbetter</a>`
      },
      offer: {
        title: 'Experiences',
        description: ''
      },
      recipe: {
        title: 'Recipes',
        description: ``
      },
      cafe: {
        title: 'Vegan cafes',
        description: `Expect good coffee and tea, natural drinks and smoothies, plant-based snacks, delicious vegan organic cakes and some friendly casual atmosphere.
        Discover vegan corner spots designed for a quick escape from the daily rush.`
      },
      article: {
        title: '',
        description: `Discover articles on vegan lifestyle, travelling and dining.
          Expect cruelty-free writing, interesting stories and sustainable ideas.`
      },
      tag: {
        title: '', // KEEP CLEAR
        header: '' // ^
      },
      contactPage: {
        header: `Contact Us`,
        description: `Is there a product or venue you recommend?
        Drop us a note or a high five 😃 \n
        hello@vegankind.com\n`
      },
      aboutPage: {
        description: `# Wondering how you can contribute to a more sustainable future?
- The best you can do is to change your diet and replace meat and dairy with plant-based and cell-based products.

# Wondering how to get the best quality plant-based and cell-based products at fair prices?

- Buy online in bulk directly from brands and wholesalers.

At Kiuki, we are reimagining the food system and the trading industry at the same time.

We connect consumers and food service businesses to manufacturers and wholesalers, allowing them to buy and sell plant-based and cell-based products at the best possible prices for both.

For consumers, we are cutting some extra fees and reducing the final price. Result: Happy customer!

For producers and wholesalers, we are improving the margins, and reducing all this annoying stuff like phone calls, specifications, invoicing and hours of operation.

Final result: Affordable Food!

Get closer to a more sustainable food future with just one click!`
      }
    }),
    new MLanguage('polish').create({
      topMenu: {
        everything: 'wszystko',
        restaurants: 'restauracje',
        restaurant: 'restauracja',
        hotels: 'hotele',
        hotel: 'hotel',
        cafes: 'kawiarnie',
        cafe: 'kawiarnia',
        magazine: 'magazyn',
        article: 'artykuł',
        articles: 'artykuły',
        type: 'typ',
        keywords: 'słowa kluczowe',
        where: 'gdzie',
        when: 'kiedy',
        location: 'lokalizacja',
        title: 'tytuł',
        back: 'Powrót'
      },
      sideMenu: {
        featured: 'polecane',
        eat: 'jedz',
        restaurants: 'restauracje',
        cafes: 'kawiarnie',
        travel: 'podróżuj',
        hotels: 'hotele',
        holidays: 'wakacje',
        experiences: 'atrakcje',
        fashion: 'moda',
        shops: 'sklepy',
        products: 'produkty',
        recipe: 'Przepis',
        magazine: '#magazyn',
        installApp: 'pobierz apkę',
        addListing: 'dodaj miejsce',
        contact: 'kontakt',
        privacy: 'privacy',
        blog: 'blog'
      },
      item: {
        day: 'dzień',
        hours: 'godziny',
        amenities: 'udogodnienia',
        photos: 'zdjęcia',
        opening_hours: 'godziny otwarcia',
        rooms: 'pokoje',
        offers: 'oferty',
        about: 'opis',
        map: 'mapa',
        reviews: 'recenzje',
        nutritional_facts: 'wartości odżywcze',
        ingredients: 'składniki',
        contact: 'kontakt'
      },
      weekDays: {
        monday: 'poniedziałek',
        tuesday: 'wtorek',
        wednesday: 'środa',
        thursday: 'czwartek',
        friday: 'piątek',
        saturday: 'sobota',
        sunday: 'niedziela'
      },
      mobileAppAdvert: {
        header: 'Zabierz Kiuki ze sobą.',
        description: 'W pobliżu zawsze są jakieś wegańskie restauracje i hotele - pomożemy Ci je znaleźć.'
      },
      addListingPage: {
        header: 'Would you like to see your favourite vegan place on Kiuki?',
        description: `Do you own or run a vegan restaurant, cafe, shop or a vegan-friendly hotel?
                      Just go to ethical.id and easily add the venue to the listing.
                      Upload your favourite vegan place or business on Kiuki and help others discover it!`
      },
      homepage: {
        title: '',
        description: `Cześć! Jesteś w <a>#kiuki</a>.
          Odkryj <a>#najlepszeweganskiemiejsca</a> na świecie.
          Przekonaj się, że <a>#wegejestlepsze</a>.
          Pokaż, że wybierasz <a>#wegańskie</a>.
          <a>#nierezygnuj</a> ze swoich wartości.
          Twoje świadome wybory mają moc, żeby ocalić zwierzęta i pomóc planecie`
      },
      restaurant: {
        title: 'Najlepsze wegańskie restauracje',
        description: `Odkryj wegańską kuchnię.
        Spodziewaj się najlepszych roślinnych potraw stworzonych na bazie sezonowych składników, stworzonych przez ludzi, którzy pasjonują się gotowaniem i wegańskim stylem życia. Zdrowe i pyszne potrawy, które są lepsze dla Ciebie i jednocześnie dla środowiska.
        Dołącz do rewolucji żywieniowej i zobacz miejsca, które zostały stworzone po to, by zachwycić każdego.`
      },
      hotel: {
        title: 'Wegańskie hotele i hotele przyjazne weganom',
        description: `Wybierz jeden z naszych hoteli położonych na całym świecie, który oferuje
        wygodne zakwaterowanie, wegańskie wyżywienie i przyjazną obsługę.
        Poznaj hotele, które łączą w sobie jakość usług, zdrowe jedzenie, lokalną kulturę
        Bez okrucieństwa dla zwierząt.`
      },
      offer: {
        title: '',
        description: ''
      },
      recipe: {
        title: 'Recipes',
        description: ``
      },
      cafe: {
        title: 'Wegańskie kawiarnie',
        description: `Odkryj kawiarnie, które oferują najlepszą kawę w mieście.`
      },
      article: {
        title: '',
        description: `Odkrywaj artykuły o wegańskim stylu życia.
        Czytaj o zdrowym żywieniu, gastronomii i podróżach.
        Publikujemy ciekawe teksty o zrównoważonym życiu bez okrucieństwa.`
      },
      tag: {
        title: '', // KEEP CLEAR
        header: '' // ^
      },
      aboutPage: {
        header: `Twoje ulubione wegańskie miejsca nie są jeszcze na kiuki?
          Daj nam znać.`,
        description: `Masz pytania?
        Mamy wszystkie odpowiedzi.
        Zostaw nam wiadomość :)
        Bardzo chcielibyśmy od Ciebie usłyszeć.<br>
        hello@kiuki.com<br>
        Kiuki Ltd
        Numer firmy: 11686383
        20-22 WENLOCK ROAD
        LONDYN
        N1 7GU
        Followuj <a href="tag/kiuki">#kiuki</a>`
      }
    })
  ]
})
