<template>
  <nav class="top-menu">
    <!-- Mobile menu -->
    <button
      class="mobile-menu"
      aria-label="Toggle Menu"
      :class="{ 'mobile-menu--closed': !sideMenuHidden }"
      @click.stop="$emit('hamburgerClicked')"
    >
      <i class="fas fa-bars"></i>
    </button>

    <router-link
      to="/"
      class="logo"
    >
      <img
        src="../assets/logo.svg"
        alt="veganKind"
        draggable="false"
        height="40"
      />
    </router-link>
    <router-link
      to="/"
      class="logo-mobile"
    >
      <img
        src="../assets/logoMobile.png"
        alt="veganKind"
        draggable="false"
        height="30"
      />
    </router-link>

    <!-- Back button -->
    <div v-if="showBackButton" class="back-button-wrapper">
      <button class="back-button" @click="handleGoBack()">
        <i class="fas fa-arrow-left"></i>
        Go back
      </button>
    </div>

    <!-- Search filter -->
    <!-- <div
      v-if="!showBackButton"
      class="filter filter--icon filter-search"
    >
      <i class="fal fa-search"></i>

      <div class="filter">
        <div
          class="filter-search__input-wrapper"
        >
          <i class="fal fa-search"></i>
          <input
            type="text"
            class="filter__input"
            placeholder="Search"
            :value="$store.state.searchValue"
          />
          <button
            @click="searchValue = ''"
            class="filter__clear-input"
            :class="{ 'filter__clear-input--visible': searchValue.length > 0 }"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div> -->
    <div v-if="!showBackButton" class="dropdown button-search-pill">
      <button
        v-if="showCategoryDropdown"
        @click.prevent.stop='handleTopDropdown()'
        class="btn-browse-dropdown"
      >
        <span style="display: flex">
          {{ selectedCategory }}
          <img
            src="@/assets/angle-down.svg"
            alt="dropdown arrow"
            style="height: 17px; width: 17px; padding-left: 5px" />
        </span>
      </button>
      <div v-if="displayTopDropdown" class="dropdown-content" style="display: flex; top: 40px;">
        <div class="dropdown-col">
          <button
            v-for='category in categories'
            :key='`sub-menu-${category.value}`'
            @click='() => { handleCategory(category); handleTopDropdown() }'
            :class="{ active: $route.name === category.value}"
            :disabled="$route.name === category.value"
          >
            {{ category.label }}
          </button>
        </div>
      </div>
      <div
        v-if="displayTopDropdown"
        class="background-close-dropdown"
        @click='() => handleTopDropdown(false)'
      ></div>
      <input
        class="searchInput"
        v-model="$store.state.searchValue"
        @input="updateSearchValue"
        :placeholder="isMobileWindow ? 'Search' : handleSearchPlaceholder()"
      >
      <button
        v-if="$store.state.searchValue.length > 0"
        class="clearSearch"
        @click="clearSearchValue"
      >X</button>
    </div>

    <div v-if="displayMobileSearch && !showBackButton" class="search button-search-pill" style="grid-column-start: span 5; grid-row-start: 2; margin-top: 10px;">
      <button
        v-if="showCategoryDropdown"
        @click.prevent.stop='handleTopDropdown()'
        class="btn-browse-dropdown"
      >
        <span style="display: flex">
          {{ selectedCategory }}
          <img
            src="@/assets/angle-down.svg"
            alt="dropdown arrow"
            style="height: 17px; width: 17px; padding-left: 5px" />
        </span>
      </button>
      <div v-if="displayTopDropdown" class="dropdown-content" style="display: flex; top: 97px;">
        <div class="dropdown-col">
          <button
            v-for='category in categories'
            :key='`sub-menu-${category.value}`'
            @click='() => { handleCategory(category); handleTopDropdown() }'
            :class="{ active: $route.name === category.value}"
            :disabled="$route.name === category.value"
          >
            {{ category.label }}
          </button>
        </div>
      </div>
      <div
        v-if="displayTopDropdown"
        class="background-close-dropdown"
        @click='() => handleTopDropdown(false)'
      ></div>
      <input
        class="searchInput"
        v-model="$store.state.searchValue"
        @input="updateSearchValue"
        :placeholder="isMobileWindow ? 'Search' : handleSearchPlaceholder()"
      >
      <button
        v-if="$store.state.searchValue.length > 0"
        class="clearSearch"
        @click="clearSearchValue"
      >X</button>
    </div>

    <div class="profile">
      <!-- <language-selector></language-selector> -->
      <button
        v-if="!showBackButton"
        @click.stop="displayMobileSearch = !displayMobileSearch"
        class="cart search"
        aria-label="Toggle search bar"
      >
        <i class="fal fa-search" />
      </button>
      <button @click.stop="$emit('openCart')" class="cart">
        <i class="fal fa-shopping-cart" />
        <div class="counter">
          <p>{{ ordersQuantity }}</p>
        </div>
      </button>
      <div class="user-container">
        <div
          v-if="userLoggedIn"
          class="player-button"
          @click.prevent.stop="$emit('openMagicSidebar')"
        >
          <img :src="userData.avatar" alt="User profile image" />
        </div>
        <div
          v-else
          style="white-space: nowrap; font-size: 19px"
          class="text-button"
          @click.prevent.stop="$emit('openMagicSidebar')"
        >
          <i class="fal fa-user"></i>
        </div>
        <div
          v-if="notificationsQuantity > 0"
          class="counter"
          @click.prevent.stop="$emit('openMagicSidebar')"
        >
          <p>{{ notificationsQuantity }}</p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  name: 'TopMenu',
  props: {
    showCategoryDropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    sideMenuHidden: Boolean,
    userLoggedIn: Boolean,
    userData: Object,
    notificationsQuantity: {
      type: Number,
      required: false,
      default: 0
    },
    ordersQuantity: Number
  },
  components: {
    // LanguageSelector
  },
  data: function () {
    return {
      categories: [
        {
          label: 'All',
          value: 'search'
        },
        {
          label: 'Shopping',
          value: 'online shopping'
        },
        {
          label: 'Hotels',
          value: 'hotels'
        },
        {
          label: 'Restaurants',
          value: 'restaurants'
        },
        {
          label: 'Experiences',
          value: 'experiences'
        },
        {
          label: 'Recipes',
          value: 'recipes'
        }
      ],
      displayMobileSearch: false,
      selectedCategory: 'Shopping',
      showBackButton: false,
      displayTopDropdown: false,
      isMobileView: false,
      showMobileExpand: false,
      searchDebounceTimer: null,
      // searchValue: '',
      windowWidth: window.innerWidth,
      queryChanged: false,
      routeUpdatedSearch: false,
      isArticlesPage: false
    }
  },
  methods: {
    clearSearchValue () {
      this.$store.commit('updateSearchValue', '')
    },
    updateSearchValue (e) {
      this.$store.commit('updateSearchValue', e.target.value)
    },
    handleGoBack () {
      if (this.isArticlesPage) {
        this.$router.push('/mag')
      } else {
        if (this.$route.meta.canGoBack) {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    handleCategory (category) {
      const query = {}
      this.$store.commit('updateSearchValue', '')

      if (category.value === 'online shopping') {
        this.$store.commit('setFilteringGeo', undefined)
        // this.inputValue = ''
      }

      this.selectedCategory = category.label

      this.$router.push({
        name: category.value,
        query
      })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    handleSearchPlaceholder () {
      switch (this.selectedCategory) {
        case 'Hotels':
          return 'Find the best vegan-friendly hotels'
        case 'Restaurants':
          return 'Find the best vegan-friendly restaurants'
        case 'Experiences':
          return 'Find vegan-friendly experiences'
        case 'Recipes':
          return 'Find the best plant-based recipes'
        default:
          return 'Find the best plant-based products'
      }
    },
    handleTopDropdown (newVal = null) {
      if (newVal === null) {
        this.displayTopDropdown = !this.displayTopDropdown
      } else {
        this.displayTopDropdown = newVal
      }
    }
  },
  computed: {
    searchValue () {
      return this.$store.state.searchValue
    },
    isMobileWindow () {
      return this.windowWidth < 540
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // Populate Selected Category
    const category = this.categories.find((el) => el.value === this.$route.name)
    this.selectedCategory = category !== undefined ? category.label : 'Shopping'
    // If the page is `/search?query={queryText}`, update store filteringQuery
    // if (window.location.pathname.includes('/search')) {
    // Get QueryParams
    const queryParams = new URLSearchParams(window.location.search)
    const query = queryParams.get('query')
    // Commit the filtering Query for algolia
    this.$store.commit('setFilteringQuery', query !== null ? query : '')
    // Auto-populate the "Search" input text
    this.$store.commit('updateSearchValue', query !== null ? query : '')
    // }

    const finalRoutes = ['article', 'item'] // Routes where "back" button should appear
    if (this.$route.name !== null && finalRoutes.includes(this.$route.name)) {
      this.showBackButton = true
      this.isArticlesPage = this.$route.name === 'article'
      // this.$router.replace({ query: { } })
    } else {
      this.showBackButton = false
    }
  },
  watch: {
    $route (to, from) {
      // Populate Selected Category
      if (to.path !== '/') {
        const category = this.categories.find((el) => el.value === this.$route.name)
        this.selectedCategory = category !== undefined ? category.label : 'Shopping'
      }

      const finalRoutes = ['article', 'item'] // Routes where "back" button should appear
      if (this.$route.name !== null && finalRoutes.includes(this.$route.name)) {
        this.showBackButton = true
        this.isArticlesPage = this.$route.name === 'article'
        // this.$router.replace({ query: { } })
      } else {
        this.showBackButton = false
      }
      if (this.queryChanged) {
        this.queryChanged = false
        return
      }
      if (to.path !== from.path && (to.name === 'category' || to.name === 'online shopping')) {
        if (this.searchValue !== null && this.searchValue.length) {
          // const queryParams = new URLSearchParams(window.location.search)
          // queryParams.set('query', this.searchValue)
          // * Avoid unnecessary route replace if the query params are already up to date
          if (this.$route.query !== undefined && this.$route.query.query !== this.searchValue) {
            this.$router.replace({ query: { query: this.searchValue } })// .catch(() => {})
          }
        } else if (to.query && to.query.query) {
          this.$store.commit('updateSearchValue', to.query.query)
          this.$store.commit('setFilteringQuery', this.searchValue)
          this.routeUpdatedSearch = true
        }
      } else if (
        to.path !== from.path &&
        !to.path.includes('search') &&
        (to.name === 'item' || to.name === 'HomePage')) {
        // const queryParams = new URLSearchParams(window.location.search)
        // queryParams.delete('query')
        if (this.$route.query.query) {
          this.$router.replace({ query: {} })
        }
        this.$store.commit('setFilteringQuery', '')
        this.$store.commit('updateSearchValue', '')
        this.routeUpdatedSearch = true
      } else if (from.query !== to.query) {
        this.$store.commit('updateSearchValue', to.query.query ? to.query.query : '')
        this.$store.commit('setFilteringQuery', this.searchValue)
        this.routeUpdatedSearch = false
      }
    },
    // We are not using simple approach with ais-search-box and refine() because
    // there is some bug in refine() with triggering search after emptying search box
    searchValue () {
      clearTimeout(this.searchDebounceTimer)
      this.searchDebounceTimer = setTimeout(() => {
        if (this.routeUpdatedSearch) {
          this.routeUpdatedSearch = false
          if (this.$route.name !== 'HomePage') {
            return
          }
        }
        if (this.searchValue.length > 0 && this.$route.name !== 'item') {
          if (this.$route.query &&
              this.$route.query.query &&
              this.$route.query.query === this.searchValue) {
            return
          }
          if (this.$route.meta.searchChangeRoute) {
            this.queryChanged = true
            if (this.selectedCategory === 'All') {
              this.$router.push({ path: '/search', query: { query: this.searchValue } })
            } else {
              this.$router.push({ path: '/online-shopping', query: { query: this.searchValue } })
            }
          } else {
            this.queryChanged = true
            this.$router.push({ query: { query: this.searchValue } })
          }
        } else {
          // Clear RouteParams
          if (this.$route.query.query) {
            this.$router.replace({ query: {} })
          }
        }
        this.$store.commit('setFilteringQuery', this.searchValue)
        // this.$router.push({
        //   path: this.selectedType === '' ? '/search' : this.selectedType,
        //   query
        // })
      }, 200)
    }
  }
}
</script>

<style lang="stylus" scoped>
.top-menu {
  @media screen and (max-width: 1050px) {
    padding: 10px 10px;
  }

  padding: 0px 20px 10px;
  width: 100%;
  min-height: 60px;
  height: auto;
  box-sizing: border-box;
  background: white;
  // border-bottom: 1px solid #e8e8e8;
  z-index: 12;
  display: grid;
  grid-template-columns: min-content min-content auto min-content min-content;
  align-items: center;

  .background-close-dropdown {
    width: 100%;
    height: 100vh;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed
  }

  .dropdown {
    position: relative;
    display: inline-block;

    @media screen and (max-width: 440px) {
      display: none !important;
    }
  }

  .dropdown-content {
    position: absolute;
    width: max-content;
    // min-width: 225px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
    border-radius: 5px;
    z-index: 51;
    .dropdown-col {
      // width: fit-content;
      width: 200px;

      button {
        border: none;
      }
    }
  }

  .dropdown-content button:hover {
    background-color: #eeeeee !important;
  }

  .dropdown-content button {
    color: black;
    padding: 10px 14px;
    text-decoration: none;
    display: block
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  .searchInput {
    display: flex;
    border: none;
    background: unset;
    padding: 3px 5px 3px 10px;
    // font-weight: 800;
    font-size: 15px;
    color: #5F5475;
    min-height: 31px;

    width: 100%

    // @media screen and (max-width: 555px) {
    //   width: 25vw
    // }
    // @media screen and (max-width: 432px) {
    //   width: 80px
    // }
  }
  .searchInput::placeholder {
    color: #5F5475;
  }

  .clearSearch {
    background: unset;
    padding-right: 10px;
    cursor: pointer;
  }

  .button-search-pill {
    // grid-column: 3 / span 1;
    // border: solid 1px #b3b3b3;
    display: flex;
    right: 16px;
    max-width: 1020px;
    margin: 0 auto;
    width: 100%;
    border-radius: 5px;
    background: #DDD7E7;

    @media screen and (max-width: 1300px) {
      right: 10px;
    }
    @media screen and (max-width: 1290px) {
      right: 5px;
    }
    @media screen and (max-width: 1280px) {
      right: 0px;
    }

    button {
      color: #5F5475;
      margin: 0;
      border: none;
      // border-right: solid 1px #5F5475;
      // background: unset;
      border-radius: 0px;
    }
  }

  .btn-browse-dropdown {
    color: black;
    // background-color: white;
    background: unset;
    border-radius: 5px;
    border: solid 1px #b3b3b3;
    padding: 10px 6px 10px 12px;
    margin: .5px 0;
    margin-right: 20px;
    font-size: 15px;
    cursor: pointer;

    i {
      padding-left: 10px;
    }
  }

  .active {
    background-color: #d9d9d9 !important;
  }

  .logo {
    grid-column: 2 / span 1;
    display: flex;
    margin-left: 15px;
    justify-self: start
    margin-right: 10px

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .logo-mobile {
    grid-column: 2 / span 1;
    display: none;
    @media screen and (max-width: 800px) {
      display: flex;
      margin-left: 10px;
      justify-self: start
      margin-right: 10px;
      width: fit-content
    }

  }

  .mobile-menu {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 20px;
    padding: 0 5px;
    margin: 0;
    background: none;
    border: none;
    justify-self: start;
    color: black;
  }

  .back-button-wrapper {
    display: flex;
    justify-content: center;
  }

  .back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;

    & i {
      margin-right: 10px;
    }
  }

  .filter {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: left;
    flex: 1 0 auto;

    & .filter {
      flex: 1 1 auto;
      padding: 0 10px;
    }

    &--icon {
      flex-direction: row;
      align-items: center;
    }

    @media screen and (max-width: 1200px) {
      & * {
        font-size: 13px !important;
      }
    }

    &__text {
      margin: 0;
      margin-bottom: 5px;
      line-height: 1;
    }

    &__input {
      background: none;
      font-family: inherit;
      border: none;
      font-size: 14px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: inherit;
        opacity: 1;
      }
    }

    &__clear-input {
      background: none;
      border: none;
      padding: 2px;
      cursor: pointer;
      visibility: hidden;
      pointer-events: none;
      margin-left: 5px;
      font-size: 11px;

      &--visible {
        pointer-events: all;
        visibility: visible;
      }
    }
  }

  .filter-search {
    grid-column: 3 / span 1;
    transition: all 0.3s ease-in-out;

    background-color: #ded7e8;
    padding: 0 10px;
    border-radius: 15px;
    margin: 0 5px 0 10px
    height: 39px

    & > i {
      font-size: 22px;
    }

    & .filter {
      padding-right: 0;
    }

    @media screen and (max-width: 1200px) {
      justify-self: center;

      @media screen and (max-width: 800px) {
        justify-self: stretch;
      }

      & > i {
        display: none;
      }

      & .filter__text {
        display: none;
      }
    }

    &__input-wrapper {
      display: flex;
      algin-items: center;

      & > i {
        display: none;
      }

      @media screen and (max-width: 1200px) {
        display: flex;
        align-items: center;

        & i {
          display: block;
          font-size: 14px !important;
        }

        & .filter__input {
          margin-left: 8px;
          font-family: inherit;
          border: none;
          width: 100%;

          &::placeholder {
            color: #000;
          }
        }
      }
    }
  }

  .filter-location {
    border-left: 1px solid #C9C9C9;
  }

  .filter-type {
    border-left: 1px solid #C9C9C9;
  }

  .filter-type, .filter-location {
    @media screen and (max-width: 800px) {
      border-left: none;
      padding: 0;
    }
  }

  // .expand-area-wrapper {

  //   @media screen and (max-width: 1200px) {
  //     max-height: 0;
  //     z-index: 4;
  //     transition: all 0.3s ease-in-out;
  //     overflow: hidden;
  //     grid-column: 1 / -1;
  //   }

  //   &--open {
  //     max-height: 200px;
  //   }
  // }

  .expand-area {
    display: flex;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 10px 20px;
      margin-top: 15px;

      & > * + * {
        margin-top: 20px;
      }
    }
  }

  .profile {
    display: flex;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    grid-column: 5 / span 1;
    justify-self: end;
  }

  .player-button {
    margin-left: 15px;
    height: 32px;
    cursor: pointer;
    width: 32px;
    border-radius: 40px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
  .user-container {
    position: relative;
  }

  .counter {
    cursor: pointer;
    position: absolute;
    width: 17px;
    height: 17px;
    bottom: 0px;
    right: 0;
    font-size: 12px;
    background-color: green;
    color: white;
    border-radius: 50px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;

    p {
      margin: 0;
      color: white;
      font-size: 13px;
      margin-left: 1px;
    }
  }

  .text-button {
    width: 32px;
    margin-left: 15px;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.8;
    }
  }

  .search {
    display: none;

    @media screen and (max-width: 440px) {
      display: inline-flex;
    }
  }

  .cart {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 19px;
    position: relative;
    margin-left: 5px;
    padding: 0 10px;
    color: black;

    .counter {
      position: absolute;
      width: 17px;
      height: 17px;
      bottom: -5px;
      right: 0;
      font-size: 12px;
      background-color: black;
      color: white;
      border-radius: 50px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-align: center;

      p {
        margin: 0;
        color: white;
        font-size: 13px;
        margin-left: 1px;
      }
    }
  }
}

.search-type-selector {
  display: flex;
  align-items: center;
  position: relative;

  & i {
    font-size: 11px;
    position: absolute;
    right: 5px;
    pointer-events: none;
    cursor: pointer;
  }

  &__select {
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    height: 100%;
    font-family: inherit;
    background: #fff;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.6;
    padding-right: 20px;
    cursor: pointer;

    &--disabled {
      color: gray;
    }
  }
}
</style>
