<template>
  <Carousel
    :autoplay="true"
    :loop="true"
    :per-page="1"
    :pagination-enabled="true"
    :autoplay-timeout="autoplayTimeout"
    class="carousel"
  >
    <Slide
      v-for="(banner, index) in homeBannerData"
      :key="`${banner.title + '-' + index}-slide`"
      class="banner"
    >
      <div
        class="bannerText"
        :style="`background-color: ${banner.backgroundColor}`">
        <!-- Left side square, text + button(s) -->
        <h2>
          <span
            :style="`background-color: ${banner.titleTextBackground};
              color: ${banner.titleTextColor};
              ${banner.titleWeight !== undefined ? `font-weight:${banner.titleWeight}` : ''};
              ${banner.titleSize !== undefined ? `font-size:${banner.titleSize}` : ''};
              ${banner.titleFont !== undefined ? `font-family: ${banner.titleFont}`: ''};`"
          >
            {{ banner.title }}
          </span>
        </h2>
        <span
          :style="`color: ${banner.descriptionTextColor};
            ${banner.descriptionFont !== undefined ? `font-family: ${banner.descriptionFont}`: ''};
            ${banner.descriptionSize !== undefined ? `font-size: ${banner.descriptionSize}`: ''};`"
          v-html="banner.description"></span>
        <button
          v-if="banner.buttonTarget && banner.buttonText"
          @click.stop="goToPage(banner.buttonTarget)"
          :style="`${banner.buttonTextSize !== undefined ? `font-size:${banner.buttonTextSize}` : ''}`"
        >
          {{ banner.buttonText }}
        </button>
      </div>
      <div class="bannerImage">
        <img v-if="index === 0" :src="getImgUrl(banner.src)" style="width: 100%"/>
        <v-lazy-image v-else :src="getImgUrl(banner.src)" style="width: 100%"/>
        <!-- Right side image -->
      </div>
    </Slide>
  </Carousel>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'HomepageBanner',
  components: {
    Carousel,
    Slide
  },
  props: {
    homeBannerData: {
      type: Array,
      required: true
    },
    // autoPlayTimeout in ms
    autoplayTimeout: {
      type: Number,
      required: false,
      default: 6000
    }
  },
  data: () => {
    return {

    }
  },
  methods: {
    getImgUrl (imageSrc) {
      return require('../assets/' + imageSrc)
    },
    goToPage (destination) {
      this.$router.push({ path: destination })
    }
  }
}
</script>
<style scoped lang="stylus">
>>> .VueCarousel-wrapper {
  border-radius: 5px;
}
>>> .VueCarousel-pagination {
  margin-top: -30px;
  margin: -30px auto 0px auto;
  width: fit-content
  z-index: 1;
  .VueCarousel-dot-container {
    margin-top: 0 !important

    button {
      margin-top: 0px !important;
    }
  }
}
.carousel {
  font-family: 'Helvetica Regular', sans-serif;
  max-height: 600px;

  .banner {
    @media screen and (max-width: 980px) {
      flex-direction: column-reverse;
      max-height: 100%;
      height: 100%
      width: 100%
    }
    max-height: 316px;
    height: 316px;
    display: flex;

    .bannerImage {
      display: flex;
      width: 70%;
      height: auto;

      img {
        border-radius: 0 5px 5px 0;
        height: 100%
        object-fit: cover;
        object-position: center;
        min-height: 200px;
        max-height: 400px;
      }

      @media screen and (max-width: 980px) {
        img {
          border-radius: 5px 5px 0 0;
          // overflow: hidden
          height: auto;
          width: 100%;
        }
        overflow: hidden
        height: auto ;
        width: 100%;
      }
    }

    .bannerText {
      border-radius: 5px 0 0 5px;
      @media screen and (max-width: 980px) {
        border-radius: 0 0 5px 5px;
        width: -webkit-fill-available;
      }
      width: 30%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      justify-content: center;

      h2 {
        text-align: left;
        margin-bottom: 20px;
        span {
          font-family: 'Bebas Neue', sans-serif;
          text-align: left;
          font-size: 30px;
          line-height: 50px;
          margin-bottom: 20px;
          // padding-inline: 4px 8px;
          // writing-mode: horizontal-tb;
          width: fit-content;
          display: inline;
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
        }
      }

      span {
        padding: 4px 8px;
        font-size: 15px;
        // padding-bottom: 20px;
        display: inline-block;
        text-align: left;
      }

      button {
        // text-decoration: underline;
        font-size: 20px;
        margin-top: 20px;
        margin-left: 8px;
        padding: 16px 32px 12px;
        border-radius: 5px;
        color: white;
        // outline: none;
        border-width: 0px;
        border-colod: black;
        background: black;
        cursor: pointer;
        width: fit-content
      }
    }
  }
}
</style>
