import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    galleryImages: [],
    openImageIndex: null,
    userData: {},
    orgData: {},
    orgsAddedDuringSession: 0,
    collectionNames: [],
    filteringQuery: '',
    filteringGeo: '',
    searchValue: '',
    sideMenuHidden: true,
    defaultSideMenuCategory: '',
    notFound: 0,
    iframePaymentUrl: '',
    serverFetchedCategories: []
  },
  getters: {
    userLoggedIn (state) {
      return typeof state.userData.id !== 'undefined'
    }
  },
  mutations: {
    setCollectionNames (state, payload) {
      if (state.collectionNames !== payload) {
        state.collectionNames = payload
      }
    },
    setGalleryImages (state, payload) {
      state.galleryImages = payload
    },
    setOpenImageIndex (state, payload) {
      state.openImageIndex = payload
    },
    setUserData (state, userData) {
      state.userData = userData
    },
    setOrgData (state, payload) {
      state.orgData = payload
    },
    addOrg (state) {
      state.orgsAddedDuringSession += 1
    },
    setFilteringQuery (state, payload) {
      state.filteringQuery = payload
    },
    setFilteringGeo (state, payload) {
      state.filteringGeo = payload
    },
    setSideMenuVisibility (state, payload) {
      state.sideMenuHidden = payload
      if (payload === true) {
        state.defaultSideMenuCategory = ''
      }
    },
    setDefaultSideMenuCategory (state, payload) {
      state.defaultSideMenuCategory = payload
    },
    setIframePaymentUrl (state, payload) {
      state.iframePaymentUrl = payload
    },
    updateSearchValue (state, payload) {
      state.searchValue = payload
    },
    updateNotFound (state) {
      state.notFound += 1
    },
    setServerFetchedCategories (state, payload) {
      state.serverFetchedCategories = payload
    }
  },
  actions: {

  }
})
