<template>
  <div class="listingSection">
    <router-link
      v-if="titleLink.length > 0"
      :to="titleLink"
    >
      <h1 class="collectionTitle">{{sectionTitle}}</h1>
    </router-link>
    <h1 v-else class="collectionTitle">
      {{sectionTitle}}
    </h1>
    <ais-instant-search
      class="listingContainer"
      :search-client="algoliaClient"
      :index-name="computedAlgoliaIndex"
    >
      <Listing
        :collection="collection"
        :ignoreVeganFlag="ignoreVeganFlag"
        :displayModal="displayModal"
        :displayTags="displayTags"
        :tag="tag"
        :horizontal="horizontal"
        :itemType="itemType"
        :userLogged="$store.getters.userLoggedIn"
        :perPage="perPage"
        :infinite="infinite"
        :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
        :disabledCollections="['vk_icecream']"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
    </ais-instant-search>
  </div>
</template>

<script>
import { algoliaClient } from '@kiukicom/listing'

export default {
  name: 'ListingSection',
  data () {
    return {
      algoliaClient,
      algoliaIndex: process.env.VUE_APP_ALGOLIA_INDEX
    }
  },
  props: {
    sectionTitle: {
      type: String,
      required: false,
      default: ''
    },
    titleLink: {
      type: String,
      required: false,
      default: ''
    },
    collection: {
      type: String,
      required: false,
      default: ''
    },
    ignoreVeganFlag: {
      type: Boolean,
      required: false,
      default: false
    },
    displayModal: {
      type: Boolean,
      required: false,
      default: true
    },
    displayTags: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: null
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false
    },
    itemType: {
      type: String,
      required: false,
      default: 'packagedfood'
    },
    perPage: {
      type: Number,
      required: false,
      default: 5
    },
    infinite: {
      type: Boolean,
      required: false,
      default: false
    },
    sortByNewest: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedAlgoliaIndex () {
      return this.sortByNewest ? `${this.algoliaIndex}_new_to_old` : this.algoliaIndex
    }

  }
}
</script>

<style scoped lang="stylus">
.listingSection {
  .listingContainer {
    min-height: 168px;
  }
  .collectionTitle {
    margin-top: 30px;
    font-family: 'Bebas Neue', sans-serif;
  }
}
</style>
