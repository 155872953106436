import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import VLazyImage from 'v-lazy-image/v2'
import MagicSideBar from '@kiukicom/sidebar'
import '@kiukicom/sidebar/dist/sidebar.css'
import SideMenu from '@kiukicom/sidemenu'
import '@kiukicom/sidemenu/dist/sidemenu.css'
import Nutritions from '@kiukicom/nutritions'
import '@kiukicom/nutritions/dist/nutritions.css'
import Ingredients from '@kiukicom/ingredients'
import '@kiukicom/ingredients/dist/ingredients.css'
import Listing from '@kiukicom/listing'
import '@kiukicom/listing/dist/listing.css'
import './languages'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VeeValidate, { Validator } from 'vee-validate'
import ToggleButton from 'vue-js-toggle-button'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueCurrencyInput from 'vue-currency-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faList, faGrip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ModalBox from '@/components/ModalBox.vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: 'https://c925b3eef69e40aaa8442561bc1c9cdc@o4504814882783232.ingest.sentry.io/4504881951342592',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      attachStacktrace: true,
      tracePropagationTargets: ['localhost', 'vegankind.com', /^\//]
    })
  ],
  ignoreErrors: [
    'Error fetching item link =>',
    'google is not defined',
    'Network Error'
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8
})

library.add(faList, faGrip)

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.component('VLazyImage', VLazyImage)
Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.use(MagicSideBar, { store })
Vue.use(SideMenu)
Vue.use(Nutritions)
Vue.use(Ingredients)

Vue.use(Listing, {
  apiURL: process.env.VUE_APP_API_URL,
  imgixDomain: process.env.VUE_APP_IMGIX_DOMAIN,
  algoliaApp: process.env.VUE_APP_ALGOLIA_APP,
  algoliaKey: process.env.VUE_APP_ALGOLIA_KEY
})

Vue.use(VeeValidate, {
  useConstraintAttrs: false
})

Validator.extend('contact_phone', (value) => /^\\+(?:[0-9] ?){6,14}[0-9]$/.test(value))

Vue.use(VueCurrencyInput)
Vue.use(ToggleButton)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('modal-box', ModalBox)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
