<template>
  <div
    v-show="show"
    class="modalBox">
    <div
      v-click-outside="() => handleCloseModal()"
      class="modalBox__container"
      :style="{ }">
      <!-- <h3>{{ title }}</h3> -->
      <!-- <div
        v-if="icon"
        class="modalBox__bigIcon">
        <i :class="'fal fa-' + icon" />
      </div> -->
      <button
        class="modalBox__close"
        @click.stop="handleCloseModal()">
        <i class="fal fa-times" />
      </button>
      <!-- <br>
      <br> -->
      <!-- <div> -->
      <iframe v-if="iframeUrl.length" :src="iframeUrl"></iframe>
      <!-- <iframe src="https://hooks.stripe.com/three_d_secure/authenticate?client_secret=src_client_secret_TOHK6KMkImzGWiWoK3Ze9iIT&livemode=false&merchant=acct_1IkuGDLHk7cBmtId&return_url=https%3A%2F%2Fhooks.stripe.com%2Fredirect%2Fcomplete%2Fsrc_1Mg9GcLHk7cBmtIdf59wbZxV%3Fclient_secret%3Dsrc_client_secret_TOHK6KMkImzGWiWoK3Ze9iIT%26source_redirect_slug%3Dtest_YWNjdF8xSWt1R0RMSGs3Y0JtdElkLF9OUjFKcnU1Q3ZHYjFaNVB3N3pDd3g4dUh5Z3NVaDBR01006iogWvQN&source=src_1Mg9GcLHk7cBmtIdf59wbZxV&source_redirect_slug=test_YWNjdF8xSWt1R0RMSGs3Y0JtdElkLF9OUjFKcnU1Q3ZHYjFaNVB3N3pDd3g4dUh5Z3NVaDBR01006iogWvQN&usage=single_use"></iframe> -->
      <!-- </div> -->
      <!-- <slot /> -->
      <!-- <footer v-if="type !== 'no-footer'">
        <template v-if="type === 'confirm'">
          <p @click="show = false">
            No
          </p>
          <b @click="handleConfirm()">Yes</b>
        </template>
        <template v-else-if="type === 'add'">
          <p @click="show = false">
            Cancel
          </p>
          <b @click="handleConfirm()">Add</b>
        </template>
        <template v-else>
          <p
            @click="
              show = false
              handleConfirm()
            ">
            Ok
          </p>
        </template>
      </footer> -->
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'ModalBox',
  directives: {
    ClickOutside
  },
  props: {
    title: String,
    icon: String,
    type: String,
    iframeUrl: String
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    handleConfirm () {
      this.$emit('onConfirm')
      this.show = false
    },
    handleCloseModal () {
      this.$store.commit('setIframePaymentUrl', '')
      this.show = false
    }
  }
}
</script>

<style scoped lang="stylus">
.modalBox
  text-align center
  position fixed
  width 100%
  height 100%
  top 0
  left 0
  display flex
  align-items center
  background-color rgba(0, 0, 0, .3)
  justify-content center
  z-index 999
  h3
    font-family: 'Helvetica Regular', sans-serif
  iframe{
    border: none;
    width: 100%;
    height: 80vh;
    min-height: 100%;
  }
  &__container
    position relative
    width 75vw
    height 80vh
    padding 35px 0px 0px 0px
    background-color white
  &__bigIcon
    margin 0 auto
    display inline-block
    width 50px
    height 50px
    text-align center
    font-size 50px
  &__close
    position absolute
    top 8px
    right 12px
    height 20px
    width 20px
    color #ccc
    border none
    background-color transparent
    cursor pointer
    font-size 20px
  footer
    position absolute
    bottom 5px
    height 50px
    width 90%
    margin 0 auto
    border-top 1px solid #eee
    display flex
    align-items center
    justify-content space-around
    *
      cursor pointer

@media screen and (max-width: 520px) {
  .modalBox {
    &__container {
      width: 100vw;
      height: 96vh;
    }
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
